import { Link, navigate } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'redux-form-validators';
import EnsoSteps from '../components/enso-steps';
import Layout from '../components/layout';
import * as actions from '../state/actions';
import '../styles/pages/registration.css';

// TODO: This page is causing errors on the console: "Cannot update a component
//     from inside the function body of a different component." The issue for
//     this is being tracked in
//     https://github.com/redux-form/redux-form/issues/4619.

const RegistrationPage = ({ valid }) => (
    <Layout requireLogin className="registration-page container">
        <div className="stepper">
            <EnsoSteps step={0} />
        </div>
        <div className="row">
            <div className="email-info container">
                <h2>Tell Us About Yourself!</h2>
                <p className="mb-3">
                    We will send your migration report to the email address you
                    provide below.
                </p>
                <RegistrationForm valid={valid} />
            </div>
        </div>
    </Layout>
);

const RegistrationForm = ({ valid }) => (
    <form
        // Disable browser built-in validation since it conflicts with JS
        // validation.
        noValidate
        // Enable using the Enter key to submit the form. If that happens,
        // intercept the event and advance the page.
        onSubmit={e => {
            if (valid) {
                navigate('/connections/');
            }
            e.preventDefault();
        }}
    >
        <Field
            component={renderField}
            type="text"
            name="firstName"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="First Name"
            validate={required()}
        />
        <Field
            component={renderField}
            type="text"
            name="lastName"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Last Name"
            validate={required()}
        />
        <Field
            component={renderField}
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            validate={email()}
        />
        <div className="mt-5 btns-primary">
            <Link className="btn btn-secondary btn-large" to="/">
                Back
            </Link>
            <button className={
                'btn btn-large float-right ' +
                (valid ? 'btn-primary' : 'd-none')
            }>
                Next
            </button>
        </div>
    </form>
);

const renderField = ({
    input,
    meta: { touched, error },
    className,
    ...otherInputProps
}) => (
    <div>
        <input
            {...input}
            {...otherInputProps}
            className={`${className} ${touched && error && 'is-invalid'}`}
        />
    </div>
);

const ConnectedForm = reduxForm({
    form: 'registration',
    onChange: (
        values,
        _,
        { updateRegistration },
    ) => updateRegistration({ ...values }),
})(RegistrationPage);

export default connect(
    ({ enso: { registration } }) => ({ initialValues: registration }),
    { updateRegistration: actions.updateRegistration },
)(ConnectedForm);
